import { createTheme, ThemeProvider } from "@mui/material";
import routes from "./routes/routes";
import { RouterProvider } from "react-router-dom";
import { organisationConfigurationDataService } from "./services/organisation-configuration";
import { useQuery } from "@tanstack/react-query";
import { generateColorPalette } from "./utilities";
import { useEffect } from "react";
import { createContext } from "react";

export const LogoContext = createContext("");
function App() {

  const { getOrganisationThemeConfiguration } = organisationConfigurationDataService();

  const organisationConfiguration = useQuery({
    queryKey: ["organisation-theme-configuration"],
    queryFn: () => getOrganisationThemeConfiguration(),
  });
  
  const orgThemeData = organisationConfiguration && organisationConfiguration.data && organisationConfiguration.data.data;
  const logo = orgThemeData && orgThemeData.logo && String(process.env.REACT_APP_S3_BASE_URL) + orgThemeData.logo || "";
  useEffect(() => {
    if (orgThemeData) {
      const favicon = document.getElementById("favicon");
      if (favicon) {
        favicon.setAttribute("href", `${process.env.REACT_APP_S3_BASE_URL}${orgThemeData.favicon}`);
      }
      document.title = orgThemeData && orgThemeData.name && `Assessments | ${orgThemeData.name}` || "Assessments | RTPL";
    }
  }, [orgThemeData]);

  const theme = createTheme({
    typography: {
      fontFamily: orgThemeData && orgThemeData.fontFamily || "sans-serif",
    },
    palette: {
      primary: generateColorPalette(
        orgThemeData && orgThemeData.primaryColor || "#ffffff"
      ),
      secondary: generateColorPalette(
        orgThemeData && orgThemeData.secondaryColor || "#ffffff"
      ),
    },
    components: {
      MuiTextField: {
        defaultProps: {
          size: "small",
        },
        styleOverrides: {
          root: {
            width: "100%",
            fontFamily: orgThemeData && orgThemeData.fontFamily || "sans-serif",
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            backgroundColor: "white",
          },
        },
      },
      MuiButton: {
        defaultProps: {
          size: "medium",
          variant: "contained",
        },
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
          },
        },
      },
    },
  });
  const dynamicTheme = {
    "--primary-color": orgThemeData && orgThemeData.primaryColor,
    "--secondary-color": orgThemeData && orgThemeData.secondaryColor,
    "--background-primary": orgThemeData && orgThemeData.primaryBackgroundColor,
    "--background-secondary": orgThemeData && orgThemeData.secondaryBackgroundColor,
    "--font-family": orgThemeData && orgThemeData.fontFamily,
  } as React.CSSProperties;

  return (
    <ThemeProvider theme={theme}>
      <LogoContext.Provider value={logo}>
        <div style={dynamicTheme}>
          <RouterProvider router={routes} />
        </div>
      </LogoContext.Provider>
    </ThemeProvider>
  );
}

export default App;