import { IOrganisationThemeConfigurationResponse } from "../../interfaces/organisation-configuration";
import HttpService from "../../services/http";

const organisationConfigurationDataService = () => {
  const { httpRequest } = HttpService();

  const getOrganisationThemeConfiguration = async () :Promise<IOrganisationThemeConfigurationResponse>=> new Promise((resolve,reject) => {
      httpRequest<IOrganisationThemeConfigurationResponse>(
        "GET",
        "config/public/organisation-theme-configuration",
    )
      .then(resolve)
      .catch(reject);
  });

  return { getOrganisationThemeConfiguration };
};

export { organisationConfigurationDataService };
