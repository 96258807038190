function timeDifference(date: string): string | null {
  const now = new Date();
  const then = new Date(date);

  const timeDiff = Math.abs(now.getTime() - then.getTime());
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  if (!seconds && !minutes && !hours) {
    return "";
  } 
  else if (seconds < 60) {
    return "Saved Just now";

  } 
  else if (minutes < 60) {
    return `Saved ${minutes} minutes ago`;
  } else {
    return `Saved ${hours} hours ago`;
  }
}
export {timeDifference};

export const numberToWord= (number: number) =>  {
  const ones = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
  const teens = ["", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
  const tens = ["", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];

  if (number === 0) return "zero";

  let word = "";

  if (number >= 100) {
      word += ones[Math.floor(number / 100)] + " hundred ";
      number %= 100;
  }

  if (number >= 20) {
      word += tens[Math.floor(number / 10)] + " ";
      number %= 10;
  } else if (number >= 11 && number <= 19) {
      return teens[number - 10];
  }

  word += ones[number];

  return word.trim();
};
export const capitalizeFirstLetter = (str : string|null)=> typeof(str) === "string" 
  ? str.charAt(0).toUpperCase() + str.slice(1) : 
  "";

export const getMinutesAndSecondsDifference = (date1: string): { minutes: number; seconds: number } => {
  const startTime = new Date();
  const completedTime = new Date(date1);
  const differenceInMilliseconds = completedTime.getTime() - startTime.getTime();
  const minutes = Math.floor(differenceInMilliseconds / (1000 * 60));
  const seconds = Math.floor((differenceInMilliseconds % (1000 * 60)) / 1000);
  return { minutes, seconds };
};

export const compare2Html = (html1: string, html2: string) => {
  const cleanText = (text: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");
    // Extract all text content, preserving line breaks in the html
    const extractTextContent = (node: Node): string => {
      if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent || "";
      }
      if (node.nodeType === Node.ELEMENT_NODE) {
        const element = node as Element;
        if (element.tagName === "DIV" || element.tagName === "P" || element.tagName === "BR") {
          return "\n" + Array.from(node.childNodes).map(extractTextContent).join("");
        }
      }
      return Array.from(node.childNodes).map(extractTextContent).join("");
    };

    // Extract text content
    let extractedText = extractTextContent(doc.body);
    
    // Clean up the extracted text
    extractedText = extractedText
      .replace(/\s+/g, " ")  // Replace multiple spaces with a single space
      .replace(/\n\s*/g, "\n")  // Remove spaces at the beginning of each line
      .trim();  // Remove leading and trailing spaces
    
    return extractedText;
  };

  const text1 = cleanText(html1);
  const text2 = cleanText(html2);
  return text1.includes(text2) || text2.includes(text1);
};

const hexToRgb = (hex: string) => {
  const bigint = parseInt(hex.slice(1), 16);
  return [
      (bigint >> 16) & 255, 
      (bigint >> 8) & 255,  
      bigint & 255  
  ];
};

const rgbToHex = (r: number, g: number, b:number) => `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;

export const generateColorPalette = (baseColor: string) => {
  const [r, g, b] = hexToRgb(baseColor);
  
  return {
      "50": rgbToHex(Math.min(r + 200, 255), Math.min(g + 200, 255), Math.min(b + 200, 255)),
      "100": rgbToHex(Math.min(r + 150, 255), Math.min(g + 150, 255), Math.min(b + 150, 255)),
      "200": rgbToHex(Math.min(r + 100, 255), Math.min(g + 100, 255), Math.min(b + 100, 255)),
      "300": rgbToHex(Math.min(r + 50, 255), Math.min(g + 50, 255), Math.min(b + 50, 255)),
      "400": baseColor,
      "500": baseColor,
      "600": rgbToHex(Math.max(r - 50, 0), Math.max(g - 50, 0), Math.max(b - 50, 0)),
      "700": rgbToHex(Math.max(r - 100, 0), Math.max(g - 100, 0), Math.max(b - 100, 0)),
      "800": rgbToHex(Math.max(r - 150, 0), Math.max(g - 150, 0), Math.max(b - 150, 0)),
      "900": rgbToHex(Math.max(r - 200, 0), Math.max(g - 200, 0), Math.max(b - 200, 0)),
      A100: rgbToHex(Math.min(r + 150, 255), Math.min(g + 150, 255), Math.min(b + 150, 255)),
      A200: rgbToHex(Math.min(r + 100, 255), Math.min(g + 100, 255), Math.min(b + 100, 255)),
      A400: rgbToHex(Math.min(r + 50, 255), Math.min(g + 50, 255), Math.min(b + 50, 255)),
      A700: rgbToHex(Math.max(r - 50, 0), Math.max(g - 50, 0), Math.max(b - 50, 0)),
      contrastText: "#ffffff"
  };
};